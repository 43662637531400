<template>
  <div class="web_box">
    <van-form @submit="submitAddress">
      <van-nav-bar
        :title="title"
        left-arrow
        @click-left="toBack"
        fixed
        class="header_class"
        :border="false"
      >
        <div class="back_icon" slot="left" />
        <!-- <div class="add_icon" slot="right" v-if="empty" /> -->
      </van-nav-bar>
      <div class="form_box">
        <van-field
          v-model="addressInfo.contactName"
          label="收货人"
          placeholder="请输入收货人"
          :rules="[{required: true, message: '请输入收货人'}]"
        />
        <van-field
          v-model="addressInfo.contactPhone"
          label="手机号码"
          placeholder="请输入手机号码"
          :rules="[{required: true, message: '请输入手机号码'}]"
        />
        <van-field
          readonly
          clickable
          name="area"
          :value="areaValue"
          label="地区选择"
          placeholder="点击选择省市区"
          @click="showArea = true"
          :rules="[{required: true, message: '点击选择省市区'}]"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            @cancel="showArea = false"
          />
        </van-popup>
        <van-field
          v-model="addressInfo.address"
          rows="3"
          type="textarea"
          placeholder="街道、小区门牌号等详细信息"
        />
      </div>
      <div class="checked_box" @click="checked = !checked">
        <div
          class="checked_icon"
          :class="checked ? 'checked_true' : 'checked_false'"
        ></div>
        <p>设为默认地址</p>
      </div>
      <div class="save_btn">
        <button>确定</button>
      </div>
    </van-form>
  </div>
</template>
<script>
import areaList from "../../area/area";
import {Toast} from "vant";
import {updateAddr, addAddr} from "@/utils/api";
export default {
  data() {
    return {
      addressInfo: {
        address: "",
        addressDetail: "",
        area: "",
        areaCode: "",
        city: "",
        cityCode: "",
        contactName: "",
        contactPhone: "",
        createtime: "",
        email: "",
        id: "",
        mobile: "",
        nickName: "",
        province: "",
        provinceCode: "",
        type: "",
        userId: "",
      },
      title: "",
      areaValue: "",
      showArea: false,
      checked: false,
      areaList: areaList, // 数据格式见 Area 组件文档
    };
  },
  mounted() {
    if (this.$route.query.type == 0) {
      let item = JSON.parse(this.$route.query.item);
      this.title = "编辑地址";
      console.log(item);
      this.addressInfo = item;
      this.areaValue = item.province + item.city + item.area;
      item.type == 0 ? (this.checked = true) : (this.checked = false);
    } else {
      this.title = "新增地址";
    }
  },
  methods: {
    
    onConfirm(values) {
      console.log(values);
      this.addressInfo.province = values[0].name;
      this.addressInfo.provinceCode = values[0].code;
      this.addressInfo.city = values[1].name;
      this.addressInfo.cityCode = values[1].code;
      this.addressInfo.area = values[2].name;
      this.addressInfo.areaCode = values[2].code;
      this.areaValue = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    submitAddress() {
      this.checked ? (this.addressInfo.type = 0) : (this.addressInfo.type = 1);
      if (this.$route.query.type == 0) {
        Toast.loading({
          message: "修改中...",
          forbidClick: true,
          duration: "1000",
        });
        updateAddr(this.addressInfo)
          .then((res) => {
            setTimeout(() => {
              Toast.success(res.msg);
            }, 1000);
            setTimeout(() => {
              this.$router.replace("/receiveAddress");
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Toast.loading({
          message: "正则添加...",
          forbidClick: true,
          duration: "1000",
        });
        addAddr(this.addressInfo)
          .then((res) => {
            setTimeout(() => {
              Toast.success(res.msg);
            }, 1000);
            setTimeout(() => {
              this.$router.replace("/receiveAddress");
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form_box {
  width: 100%;
  margin-top: 108px;
  background-color: #12172c;
  .van-field {
    background-color: transparent !important;
    min-height: 120px;
    font-size: 28px;
    // align-items: center;
    // line-height: 40px;
    padding: 36px;
  }
}
.checked_box {
  height: 120px;
  margin-top: 20px;
  background-color: #12172c;
  padding: 0 36px;
  display: flex;
  justify-content: left;
  align-items: center;
  .checked_icon {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .checked_true {
    background-image: url("../../assets/address/checked_true.png");
  }
  .checked_false {
    background-image: url("../../assets/address/checked_false.png");
  }
  p {
    color: #00ccff;
    font-size: 28px;
    margin-left: 16px;
  }
}
.save_btn {
  widows: 100%;
  text-align: center;
  button {
    width: 90%;
    background: #00ccff;
    border-radius: 44px;
    height: 88px;
    text-align: center;
    border: none;
    outline: none;
    color: #fff;
    margin: 60px auto 0;
  }
}
</style>
<style lang="scss">
.van-cell__title {
  color: #00ccff;
}
.van-field__control {
  color: #37708f;
}
</style>
